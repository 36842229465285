import Navigatie from "../../components/navigaties";
import Footers from "../../components/footers";
import { Helmet } from "react-helmet";
import BreadCrumb from "../../components/breadcrumb";

function P_Student_Inschrijven(props) {
    function renderNav() {
        if (props.location.pathname.includes("student")) {
            return <Navigatie type="studenten" />;
        } else if (props.location.pathname.includes("bedrijf")) {
            return <Navigatie type="bedrijven" />;
        } else {
            return <Navigatie type="home" />;
        }
    }

    function renderBreadCrumb() {
        if (props.location.pathname.includes("student")) {
            return <BreadCrumb prev={[["/student", "Home"]]} now="Wefynd" />;
        } else if (props.location.pathname.includes("bedrijf")) {
            return <BreadCrumb prev={[["/bedrijf", "Home"]]} now="Wefynd" />;
        } else {
            return <BreadCrumb prev={[["/", "Home"]]} now="Wefynd" />;
        }
    }

    return (
        <div className="mr-t-33">
            <Helmet>
                <title>HR - Wefynd</title>
            </Helmet>
            {renderNav()}
            <div className="container w-71.5 xl-w-82.5 m-w-99">
                {renderBreadCrumb()}
                <div className="pd-4 mr-t-8">
                    <h1 className="mr-b-8">Inschrijven Job en Stage event</h1>
                    <p>
                        We zijn verheugd om de samenwerking met <strong className="c-blue-440">We fynd</strong> aan te kondigen.
                    </p>
                    <p>
                        Wil je deelnemen aan ons Job en Stage event? Klik op de onderstaande banner!
                    </p>
                    <a href="https://www.wefynd.com/nl/evenement/hermes-gent-job-en-stage-event-2025" target="_blank" rel="noreferrer">
                        <img src="../images/jse2025/wefynd_desktop.png" alt="Wefynd" className="w-50 l-w-60 m-w-70 m-hidden mr-b-8 mr-t-5 border-r-15" />

                        <img src="../images/jse2025/wefynd_mobiel.png" alt="Wefynd" className="hidden w-50 xs-w-100 s-w-70 m-block center mr-b-8 mr-t-5 border-r-15" />
                    </a>
                    <br />
                </div>
            </div>
            <Footers type="main" />
        </div>
    );
}

export default P_Student_Inschrijven;
