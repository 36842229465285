import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { MessengerChat } from "react-messenger-chat-plugin";
//css
import "./style/RVB.css";
import "../node_modules/react-notifications-component/dist/theme.css";
import "animate.css";

//other imports
import { Route, BrowserRouter as Router, Redirect } from "react-router-dom";
import ReactNotification from "react-notifications-component";
import { config } from "./config";
//main pages
import P_Studenten from "./pages/studenten";
import P_Bedrijf from "./pages/bedrijf";
import P_Home from "./pages/home";
import P_Login from "./pages/login";
import P_Registrate from "./pages/registrate";
import P_ValideerEmail from "./pages/valideerEmail";
import P_ResetWachtwoord from "./pages/resetWachtwoord";
import P_Logout from "./pages/logout";
import P_About from "./pages/about";
import P_JSEvent from "./pages/j&s";
import P_MijnAccount from "./pages/mijnAccount";
import P_Webinars from "./pages/webinars";
import P_PrivacyPolicy from "./pages/privacyPolicy";
import P_MijnBedrijf from "./pages/mijnBedrijf";
import P_CVBundel from "./pages/cvBundel";
import P_ResetWachtwoordAanvraag from "./pages/resetWachtwoordAanvragen";
//import P_Beta2 from './pages/beta2'
//import P_Beta3 from './pages/beta3'
import P_JS_Inschrijven from "./pages/j&s/inschrijven";
import P_JS_Inschrijven_Voltooid from "./pages/j&s/inschrijven_voltooid";
import P_JS_Standplaats from "./pages/j&s/standplaats";

import P_Bedrijven from "./pages/bedrijf_paginas/bedrijven";
import P_Bedrijf_id from "./pages/bedrijf_paginas/bedrijf";

//studenten pagina's
import P_Jobs from "./pages/student_paginas/jobs";
import P_Job_id from "./pages/student_paginas/job";
import P_Stages from "./pages/student_paginas/stages";
import P_Stage_id from "./pages/student_paginas/stage";
// bedrijf pagina's

import P_Diensten from "./pages/bedrijf_paginas/diensten";

//paneel pagina's

import P_Paneel from "./pages/paneel/paneel";
import P_Paneel_Bedrijven from "./pages/paneel/bedrijf/bedrijven";
import P_Paneel_Bedrijf_Edit from "./pages/paneel/bedrijf/bewerk";
import P_Paneel_Bedrijf_Edit_Info from "./pages/paneel/bedrijf/bewerk-info";
import P_Paneel_Bedrijven_Info from "./pages/paneel/bedrijf/bedrijven-info";
import P_Paneel_Jobs from "./pages/paneel/jobs/jobs";
import P_Paneel_Jobs_Bewerken from "./pages/paneel/jobs/bewerk";
import P_Paneel_Stages from "./pages/paneel/stage/stages";
import P_Paneel_Stage_Bewerken from "./pages/paneel/stage/bewerk";
import P_Paneel_JS_Home from "./pages/paneel/j&s/home";
import P_Paneel_JSInschrijvingen from "./pages/paneel/j&s/inschrijvingen";
import P_Paneel_JS_Inschrijving_Bewerking from "./pages/paneel/j&s/bewerk";
import P_Paneel_JS_Deelnemers from "./pages/paneel/j&s/deelnemers";
import P_Paneel_Accounts_Accounts from "./pages/paneel/accounts/accounts";
import P_Solicitatieavond from "./pages/solicitatieavond";
import P_Student_Inschrijven from "./pages/student_paginas/inschrijven";

function App() {
    // eslint-disable-next-line
    let [claims, setClaims] = useState(localStorage.getItem("claims") !== null ? JSON.parse(localStorage.getItem("claims")) : null);

    useEffect(() => {
        if (claims !== null) {
            const now = new Date().getTime() / 1000;
            if (now >= claims.exp) {
                const refresh = localStorage.getItem("refresh");
                const claim = JSON.parse(atob(refresh.split(".")[1]));
                if (now >= claim.exp) {
                    localStorage.clear();
                    window.location.replace(config.base + "/login");
                }
            }
        }
        // eslint-disable-next-line
    }, []);

    return (
        <div className="App font-inter">
            <ReactNotification />
            <Router>
                {/* main paginas */}
                <Route exact path="/" component={P_Home} />
                <Route exact path="/home" component={P_Home} />
                <Route exact path="/student" component={P_Studenten} />
                <Route exact path="/bedrijf" component={P_Bedrijf} />
                <Route exact path="/login" component={P_Login} />
                <Route exact path="/registreer" component={P_Registrate} />
                <Route exact path="/valideer" component={P_ValideerEmail} />
                <Route exact path="/valideer/:key" component={P_ValideerEmail} />
                <Route exact path="/reset-wachtwoord/:token" component={P_ResetWachtwoord} />
                <Route exact path="/logout" component={P_Logout} />
                <Route exact path="/mijn-account" component={P_MijnAccount} />
                <Route exact path="/webinars" component={P_Webinars} />
                <Route exact path="/privacy-policy" component={P_PrivacyPolicy} />
                <Route exact path="/mijn-bedrijf/:uuid" component={P_MijnBedrijf} />
                <Route exact path="/cv-bundel" component={P_CVBundel} />
                <Route exact path="/reset" component={P_ResetWachtwoordAanvraag} />
                <Route exact path="/sollicitatieavond" component={P_Solicitatieavond} />

                {/*<Route exact path="/beta" component={P_Beta3} /> */}

                <Route exact path="/j&s/inschrijven" component={P_JS_Inschrijven} />
                <Route exact path="/j&s/inschrijven/voltooid" component={P_JS_Inschrijven_Voltooid} />
                <Route exact path="/j&s/inschrijven/standplaats" component={P_JS_Standplaats} />
                <Route exact path="/j&s/inschrijven/standplaats/:id" component={P_JS_Standplaats} />

                {/* student paginas */}
                <Route exact path="/student/bedrijven" component={P_Bedrijven} />
                <Route exact path="/student/bedrijven/:naam/:id" component={P_Bedrijf_id} />
                <Route exact path="/student/over-ons" component={P_About} />
                <Route exact path="/student/job-en-stage-event" component={P_JSEvent} />
                <Route exact path="/student/wefynd" component={P_Student_Inschrijven} />
                <Route exact path="/student/jobs" component={P_Jobs} />
                <Route exact path="/student/jobs/:naam/:id" component={P_Job_id} />
                <Route exact path="/student/stages/:naam/:id" component={P_Stage_id} />
                <Route exact path="/student/stages" component={P_Stages} />
                <Route exact path="/student/mijn-account" component={P_MijnAccount} />

                {/* bedrijf paginas */}
                <Route exact path="/bedrijf/bedrijven" component={P_Bedrijven} />
                <Route exact path="/bedrijf/bedrijven/:naam/:id" component={P_Bedrijf_id} />
                <Route exact path="/bedrijf/over-ons" component={P_About} />
                <Route exact path="/bedrijf/job-en-stage-event" component={P_JSEvent} />
                <Route exact path="/bedrijf/diensten" component={P_Diensten} />
                <Route exact path="/bedrijf/mijn-account" component={P_MijnAccount} />

                {/* paneel paginas */}
                <Route exact path="/paneel" component={P_Paneel} />
                <Route exact path="/paneel/bedrijven" component={P_Paneel_Bedrijven} />
                <Route exact path="/paneel/bedrijven/:naam/:id" component={P_Bedrijf_id} />
                <Route exact path="/paneel/bedrijf/:id/bewerk/" component={P_Paneel_Bedrijf_Edit} />
                <Route exact path="/paneel/bedrijf/:id/bewerk-info/" component={P_Paneel_Bedrijf_Edit_Info} />
                <Route exact path="/paneel/bedrijven-info" component={P_Paneel_Bedrijven_Info} />
                <Route exact path="/paneel/jobs" component={P_Paneel_Jobs} />
                <Route exact path="/paneel/jobs/:naam/:id" component={P_Job_id} />
                <Route exact path="/paneel/job/:id/bewerk" component={P_Paneel_Jobs_Bewerken} />
                <Route exact path="/paneel/stage" component={P_Paneel_Stages} />
                <Route exact path="/paneel/stages/:naam/:id" component={P_Stage_id} />
                <Route exact path="/paneel/stage/:id/bewerk" component={P_Paneel_Stage_Bewerken} />
                <Route exact path="/paneel/j&s" component={P_Paneel_JS_Home} />
                <Route exact path="/paneel/j&s/:jaar/inschrijvingen" component={P_Paneel_JSInschrijvingen} />
                <Route exact path="/paneel/j&s/inschrijving/:id/bewerk/" component={P_Paneel_JS_Inschrijving_Bewerking} />
                <Route exact path="/paneel/j&s/:jaar/deelnemers" component={P_Paneel_JS_Deelnemers} />
                <Route exact path="/paneel/accounts" component={P_Paneel_Accounts_Accounts} />
            </Router>
            <MessengerChat pageId="100885918312507" language="nl_BE" />
        </div>
    );
}

const rootElement = document.getElementById("root");
ReactDOM.render(<App />, rootElement);
